import { IN_SVILUPPO } from ':constants'
import { pratiche } from ':scripts/pratiche'

/**
 *
 * @param {{
 *  sessione: import(':types').Sessione,
 *  numero_pratica: string,
 * }} payload
 * @returns
 */
export async function pratica({ sessione, numero_pratica }) {
  try {
    const elenco_pratiche = await pratiche({
      sessione,
      filtro: {
        DataVendita: [false, false],
        DataPartenza: [false, false],
        DataRientro: [false, false],
        DataCreazione: [false, false],
        NumeroAllegati: [0, 99999],
        NumeroPratica: numero_pratica,
        Destinazione: '',
        Descrizione: '',
        CognomeNomeCliente: '',
      },
    })
    return elenco_pratiche[0] || false
  } catch (e) {
    console.warn(e)
    return false
  }
}

/**
 *
 * @param {{promessa_pratica: Promise<false | import(':types').Pratica>}} payload
 * @returns {Promise<false|import(':types').Pratica>}
 */
export async function pratica_sviluppo({ promessa_pratica }) {
  const pratica = await promessa_pratica
  if (pratica) {
    return pratica
  } else if (IN_SVILUPPO) {
    return {
      AccountLoginID: '',
      AgenziaID: '',
      CognomeCliente: location.host,
      DataCreazione: '',
      DataPartenzaCliente: '',
      DataRientroCliente: '',
      Descrizione: location.host,
      DescrizioneDestinazione: location.host,
      DescrizioneDestinazioneSintetica: location.host,
      NomeCliente: location.host,
      NumeroAllegati: 1,
      NumeroAllegatiPubblici: 1,
      NumeroInterno: 1,
      NumeroPratica: '1',
      NumeroRigheTotali: 1,
      PraticaID: '',
      Titolo: location.host,
      Utente: location.host,
      Eliminata: false,
      ColoreTesto: [],
      ColoreSfondo: [],
      ImmagineDiCopertina: '',
    }
  }

  return false
}
