import axios from 'axios'
import { get } from 'svelte/store'
import { dayjs } from ':scripts/dayjs'
import { store_otp } from ':stores/store_otp'
import { store_url_facilews3 } from ':stores/store_url_facilews3'

/**
 * Ottiene pratiche in base ad un Filtro.
 * @param {import(':types').PayloadPratiche} payload
 */
export async function pratiche({
  sessione,
  filtro,
  numero_righe_totali = 0,
  offset = 0,
  limite_righe = 10,
  recenti = false,
}) {
  try {
    const $store_url_facilews3 = get(store_url_facilews3)
    const $store_otp = get(store_otp)

    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaFacileWS3<Array<import(':types').Pratica>>>}
     */
    const Risposta = await axios.post(
      `${$store_url_facilews3}/Api/Rest/${sessione.Utente.CodiceAgenzia}/Pratica/Find?OTP=${$store_otp}&Token=${sessione.Utente.TokenFacileWS}`,
      {
        AccountLoginID: sessione?.Utente?.AccountLoginId ?? '',
        // AccountLoginID: '',
        NumeroRigheTotali: numero_righe_totali,
        Offset: offset,
        RowLimit: limite_righe,
        NumeroPratica: filtro.NumeroPratica,
        DataVenditaInizio: !filtro.DataVendita[0]
          ? ''
          : dayjs(filtro.DataVendita[0]).format('YYYYMMDD'),
        DataVenditaFine: !filtro.DataVendita[1]
          ? ''
          : dayjs(filtro.DataVendita[1]).format('YYYYMMDD'),
        DataPartenzaInizio: !filtro.DataPartenza[0]
          ? ''
          : dayjs(filtro.DataPartenza[0]).format('YYYYMMDD'),
        DataPartenzaFine: !filtro.DataPartenza[1]
          ? ''
          : dayjs(filtro.DataPartenza[1]).format('YYYYMMDD'),
        DataRientroInizio: !filtro.DataRientro[0]
          ? ''
          : dayjs(filtro.DataRientro[0]).format('YYYYMMDD'),
        DataRientroFine: !filtro.DataRientro[1]
          ? ''
          : dayjs(filtro.DataRientro[1]).format('YYYYMMDD'),
        DataCreazioneInizio: !filtro.DataCreazione[0]
          ? ''
          : dayjs(filtro.DataCreazione[0]).format('YYYYMMDD'),
        DataCreazioneFine: !filtro.DataCreazione[1]
          ? ''
          : dayjs(filtro.DataCreazione[1]).format('YYYYMMDD'),
        NumeroMinimoAllegati: filtro.NumeroAllegati[0],
        NumeroMassimoAllegati: filtro.NumeroAllegati[1],
        Destinazione: filtro.Destinazione,
        Descrizione: filtro.Descrizione,
        CognomeNomeCliente: filtro.CognomeNomeCliente,
        Recenti: recenti ? 1 : 0,
      },
    )

    if (Risposta.data.data) {
      for (var i in Risposta.data.data) {
        if (Risposta.data.data[i].ImmagineDiCopertina) {
          Risposta.data.data[i].ImmagineDiCopertina = Risposta.data.data[
            i
          ].ImmagineDiCopertina.replace(
            '{tokenUtente}',
            sessione.Utente.TokenFacileWS,
          )
        }
      }
    }

    return Risposta.data.data || []
  } catch (e) {
    console.warn(e)
    return []
  }
}
