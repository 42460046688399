import axios from 'axios'
import { get } from 'svelte/store'
import { store_messaggio } from ':stores/store_messaggio'
import { store_url_sferanet } from ':stores/store_url_sferanet'
import { IOTNULL } from ':constants'

/**
 * Invia una mail con istruzioni per recuperare la password.
 * @param {{
 *  dominio: import(':types').MetadatiDominio,
 *  email: string,
 * }} payload
 * @returns MessaggioID se il store_messaggio è stato creato, altrimenti false.
 */
export async function richeidi_email_recupero_password({ dominio, email }) {
  try {
    const $store_url_sferanet = get(store_url_sferanet)

    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaSferaNet<string>>}
     */
    const Risposta = await axios.post(
      `${$store_url_sferanet}/CompFacileLogin/Auth.json`,
      JSON.stringify({
        Email: email,
      }),
      {
        headers: {
          'x-http-method-override': 'EmailResetPassword',
          'content-type': 'application/json',
          'codiceagenzia': dominio.Codice,
        },
      },
    )

    // @ts-ignore
    if (IOTNULL === Risposta.data) {
      store_messaggio.set({ testo: IOTNULL })
      return false
    }

    if (Risposta.data.code >= 300) {
      store_messaggio.set({ testo: Risposta.data.message })
      return false
    }

    return Risposta.data.data
  } catch (e) {
    console.warn(e)
    return false
  }
}
