<style>
  canvas {
    align-self: center;
    justify-self: center;
  }
</style>

<script>
  import { canvas_2d } from ':scripts/canvas_2d'
  import { scala_immagine } from ':scripts/scala_immagine'
  import { taglia_immagine } from ':scripts/taglia_immagine'

  import { onMount } from 'svelte'
  import { fabric } from 'fabric'
  import { IN_SVILUPPO } from ':constants'

  /**
   * @type {number}
   */
  export let larghezza
  /**
   * @type {number}
   */
  export let altezza

  /**
   * @type {HTMLCanvasElement}
   */
  let canvas
  /**
   * @type {fabric.Canvas}
   */
  let fabric_canvas
  /**
   * @type {CanvasRenderingContext2D}
   */
  let contesto
  /**
   * @type {false | ImageData}
   */
  let immagine_cache = false
  // let Caricato = false

  // const SPESSORE_MOUSE = 5
  /**
   * @type {import('svelte/store').Writable<import(':types').Punto>}
   */
  // const punto_cache = writable({ x: 0, y: 0 })
  /**
   * @type {false | import(':types').Punto}
   */
  // const punto_cache_precedente = writable(false)
  // const spessore_cache = writable(SPESSORE_MOUSE)
  // const attivo_cache = writable(false)

  onMount(function run() {
    fabric_canvas = new fabric.Canvas(canvas)
    fabric_canvas.isDrawingMode = true
    fabric_canvas.freeDrawingBrush.width = 5
    fabric_canvas.freeDrawingBrush.color = '#000000'
    fabric_canvas.setDimensions({
      width: larghezza,
      height: altezza,
    })
  })

  onMount(function run() {
    // @ts-ignore
    contesto = canvas.getContext('2d')
  })

  $: aggiornamento_dimensione({ canvas, larghezza, altezza })
  /**
   *
   * @param {{canvas: HTMLCanvasElement, larghezza: number, altezza: number}} payload
   */
  async function aggiornamento_dimensione({ canvas, larghezza, altezza }) {
    if (!canvas) return
    await ridimensione({ nuova_larghezza: larghezza, nuova_altezza: altezza })
  }

  function pulisci() {
    fabric_canvas.clear()
  }

  function get_immagine() {
    return contesto.getImageData(0, 0, canvas.width, canvas.height)
  }

  /**
   * Converte l'immagine del canvas in un'immagine base64.
   * @param larghezza_massima Se valorizzato e la larghezza dell'immagine supera questo valore, l'immagine verrà ridimensionata.
   * @param altezza_massima Se valorizzato e l'altezza dell'immagine supera questo valore, l'immagine verrà ridimensionata.
   */

  /**
   *
   * @param {{
   *   larghezza_massima: false|number,
   *   altezza_massima: false|number,
   * }} payload
   * @return {Promise<{
   *  immagine_base64:string,
   *  larghezza:number,
   *  altezza:number,
   * }>}
   */
  function get_immagine_base64({
    larghezza_massima = false,
    altezza_massima = false,
  }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function run(resolve, error) {
      const { contesto, canvas } = canvas_2d({ larghezza, altezza })

      if (immagine_cache) contesto.putImageData(immagine_cache, 0, 0)
      contesto.putImageData(get_immagine(), 0, 0)

      let { ok, errore, dimensioni, immagine } = taglia_immagine({
        immagine: get_immagine(),
        larghezza,
        altezza,
        taglia_lato: {
          alto: true,
          basso: true,
          sinistro: true,
          destro: true,
        },
      })

      if (!ok) throw "Errore durante l'estrazione dell'immagine."

      let scala = 1

      if (false === larghezza_massima) larghezza_massima = larghezza
      if (false === altezza_massima) altezza_massima = altezza

      if (dimensioni.larghezza > larghezza_massima) {
        scala = larghezza_massima / dimensioni.larghezza
      }

      if (dimensioni.altezza > altezza_massima) {
        if (scala > altezza_massima / dimensioni.altezza)
          scala = altezza_massima / dimensioni.altezza
      }

      if (1 !== scala) {
        const risultato = taglia_immagine({
          // @ts-ignore
          immagine: await scala_immagine({ immagine, scala }),
          larghezza,
          altezza,
          taglia_lato: {
            alto: true,
            basso: true,
            destro: true,
            sinistro: true,
          },
        })
        ok = risultato.ok
        errore = risultato.errore
        dimensioni = risultato.dimensioni
        immagine = risultato.immagine
      }

      canvas.width = dimensioni.larghezza
      canvas.height = dimensioni.altezza

      contesto.putImageData(immagine, 0, 0)

      let immagine_risultato = new Image()
      let data_url = canvas.toDataURL('image/png')
      immagine_risultato.src = data_url
      //image.style.display = "none";
      document.body.appendChild(immagine_risultato)
      immagine_risultato.onload = async function run() {
        const data_base64 = data_url.split('base64,', 2)[1]
        resolve({
          immagine_base64: data_base64,
          larghezza: dimensioni.larghezza,
          altezza: dimensioni.altezza,
        })
        if (IN_SVILUPPO) {
          console.log(
            immagine_risultato,
            immagine_risultato.src,
            dimensioni.larghezza,
            dimensioni.altezza,
          )
          immagine_risultato.style.display = 'none'
        } else document.body.removeChild(immagine_risultato)
      }
    })
  }

  // function DisegnaImmagine(Immagine: ImageData, Punto: Punto = { x: 0, y: 0 }) {
  //   Contesto.putImageData(Immagine, Punto.x, Punto.y)
  // }

  /**
   *
   * @param {{
   *  nuova_larghezza: number,
   *  nuova_altezza: number,
   * }} payload
   */
  function ridimensione({ nuova_larghezza, nuova_altezza }) {
    return new Promise(function run(resolve) {
      const larghezza_superiore =
        nuova_larghezza > larghezza ? nuova_larghezza : larghezza
      const larghezza_inferiore =
        nuova_altezza > altezza ? nuova_altezza : altezza

      const immagine_originale = get_immagine()
      canvas.width = larghezza_superiore
      canvas.height = larghezza_inferiore

      // FCanvas.width = LarghezzaSuperiore
      // FCanvas.height = AltezzaSuperiore
      fabric_canvas.setDimensions({
        width: larghezza_superiore,
        height: larghezza_inferiore,
      })

      // @ts-ignore
      const { ok, errore, dimesioni, immagine } = taglia_immagine({
        immagine: immagine_originale,
        larghezza: larghezza_superiore,
        altezza: larghezza_inferiore,
        taglia_lato: {
          alto: true,
          basso: true,
          destro: true,
          sinistro: true,
        },
      })

      // if (!ImmagineCache) ImmagineCache = GetImmagine()
      // else
      //   ImmagineCache = unisciImmagini(
      //     ImmagineCache,
      //     Immagine,
      //     LarghezzaSuperiore,
      //     AltezzaSuperiore
      //   )

      // DisegnaImmagine(ImmagineTagliata, {
      //   x: NuovaLarghezza / 2 - DimensioniTagliate.Larghezza / 2,
      //   y: NuovaAltezza / 2 - DimensioniTagliate.Altezza / 2,
      // })
      resolve(true)
    })
  }

  /**
   * Il cursore ha premuto sul canvas e ora dobbiamo
   * ricordarci questa posizione per tutta la durata del tratto,
   * finche il cursore non viene rilasciato di nuovo.
   * @param x
   * @param y
   * @param Raggio nel caso di un mouse è un valore statico,
   * nel caso del touch screen deve essere la pressione del dito
   * se disponibile, altrimenti un valore statico come nel caso del mouse.
   */
  // function DisegnaLinea(Inizio: Punto, Fine: Punto, Spessore: number) {
  //   if (!Canvas) return
  //   Contesto.lineWidth = Spessore
  //   Contesto.beginPath()
  //   Contesto.moveTo(Inizio.x, Inizio.y)
  //   Contesto.lineTo(Fine.x, Fine.y)
  //   Contesto.closePath()
  //   Contesto.stroke()
  // }

  function GetPosizione() {
    return canvas.getBoundingClientRect()
  }

  // ANCHOR Eventi touch
  // function InizioTouch(Evento: TouchEvent) {
  //   const Posizione = GetPosizione()
  //   const x = Evento.touches[0].clientX - Posizione.x
  //   const y = Evento.touches[0].clientY - Posizione.y
  //   const Spessore = Evento.touches[0].radiusX

  //   const Punto = { x, y }

  //   $AttivoCache = true
  //   $SpessoreCache = Spessore
  //   $PuntoCachePrecedente = Punto
  //   $PuntoCache = Punto
  // }

  // function MovimentoTouch(Evento: TouchEvent) {
  //   if (!$AttivoCache || AggiornamentoPuntoCacheInCorso) return
  //   const Posizione = GetPosizione()
  //   const x = Evento.touches[0].clientX - Posizione.x
  //   const y = Evento.touches[0].clientY - Posizione.y

  //   if (x === $PuntoCache.x && y === $PuntoCache.y) return

  //   const Spessore = Evento.touches[0].radiusX

  //   const Punto = { x, y }

  //   $SpessoreCache = Spessore
  //   // $PuntoCachePrecedente = $PuntoCache
  //   $PuntoCache = Punto

  //   AggiornamentoPuntoCacheInCorso = true
  //   requestAnimationFrame(AggiornamentoPuntoCache)
  // }

  // function FineTouch() {
  //   $AttivoCache = false
  // }

  // ANCHOR Eventi mouse
  // function InizioMouse(Evento: MouseEvent) {
  //   const Posizione = GetPosizione()
  //   const x = Evento.clientX - Posizione.x
  //   const y = Evento.clientY - Posizione.y

  //   const Punto = { x, y }

  //   $AttivoCache = true
  //   $SpessoreCache = SPESSORE_MOUSE
  //   $PuntoCachePrecedente = Punto
  //   $PuntoCache = Punto
  // }

  // function MovimentoMouse(Evento: MouseEvent) {
  //   if (!$AttivoCache || AggiornamentoPuntoCacheInCorso) return
  //   const Posizione = GetPosizione()
  //   const x = Evento.clientX - Posizione.x
  //   const y = Evento.clientY - Posizione.y

  //   if (x === $PuntoCache.x && y === $PuntoCache.y) return

  //   const Punto = { x, y }

  //   $AttivoCache = true
  //   $SpessoreCache = SPESSORE_MOUSE
  //   // $PuntoCachePrecedente = $PuntoCache
  //   $PuntoCache = Punto
  //   AggiornamentoPuntoCacheInCorso = true
  //   requestAnimationFrame(AggiornamentoPuntoCache)
  // }

  // function FineMouse() {
  //   $AttivoCache = false
  // }
</script>

<slot {get_immagine} {get_immagine_base64} {pulisci} />
<!-- <canvas
  bind:this={Canvas}
  on:touchstart={InizioTouch}
  on:touchmove={MovimentoTouch}
  on:touchend={FineTouch}
  on:mousedown={InizioMouse}
  on:mousemove={MovimentoMouse}
  on:mouseout={FineMouse}
  on:mouseup={FineMouse}
  {...$$restProps}
/> -->
<canvas
  bind:this={canvas}
  {...$$restProps}
  width={larghezza}
  height={altezza}
/>
