<style lang="scss">
  .indizio-firma {
    height: 2px;
    width: 70vw;
    background-color: #ff0000;
    pointer-events: none;
  }
  .attenzione {
    background: rgba(100, 100, 100, 0.1);
    border-radius: 1rem;
    padding: 1rem;
  }
</style>

<script>
  import { store_url_facilews3 } from ':stores/store_url_facilews3'
  import { contratto, contratto_sviluppo } from ':scripts/contratto'
  import {
    mdiDownloadBox,
    mdiEye,
    mdiSend,
    mdiSignatureFreehand,
    mdiTrashCan,
  } from '@mdi/js'

  import ClausoleContrattoFirma from ':components/dettaglio-contratto-firma/clausole-contratto-firma.svelte'
  import FormClienteContrattoFirma from ':components/dettaglio-contratto-firma/form-cliente-contratto-firma.svelte'
  import FormOperatoreContrattoFirma from ':components/dettaglio-contratto-firma/form-operatore-contratto-firma.svelte'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'
  import axios from 'axios'
  import { store_otp } from ':stores/store_otp'

  import CanvasManoLibera from ':components/canvas-mano-libera.svelte'
  import Popup from ':components/popup.svelte'
  import { scale } from 'svelte/transition'

  /**
   * @type {import(':types').Sessione}
   */
  export let sessione
  /**
   * @type {import(':types').Pratica}
   */
  export let pratica
  /**
   * @type {import(':types').AllegatoPratica}
   */
  export let allegato
  /**
   * @type {boolean}
   */
  export let visualizza_app_bar_principale
  /**
   * @type {import(':types').MetadatiDominio}
   */
  export let dominio
  export let otp_invio_proposta_firma = ''
  /**
   * @type {function():void}
   */
  export let abilita_pull_to_refresh
  /**
   * @type {function():void}
   */
  export let disabilita_pull_to_refresh

  /**
   * @type {[
   *  false|import(':types').FirmaContratto,
   *  false|import(':types').FirmaContratto,
   *  false|import(':types').FirmaContratto,
   *  false|import(':types').FirmaContratto,
   *  false|import(':types').FirmaContratto,
   * ]}
   */
  let firme = [false, false, false, false, false] //massimo 5 firme, non inviare di più

  let numero_clausole_valide = 0

  let firma_in_corso = false

  let salvataggio_in_corso = false
  let larghezza_corpo_firma = 1
  let altezza_corpo_firma = 1

  /**
   * @type {[boolean,boolean,boolean,boolean,boolean]}
   */
  let clausole_accettate = [false, false, false, false, false]

  async function account_pratica() {
    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaFacileWS3<import(':types').Account>>}
     */
    const risposta = await axios.get(
      `${$store_url_facilews3}/Api/Rest/${sessione.Utente.CodiceAgenzia}/Account/FindByPraticaID/${pratica.PraticaID}?OTP=${otp_invio_proposta_firma}&Token=${sessione.Utente.TokenFacileWS}`,
    )
    return risposta.data.data
  }

  function apri_contratto() {
    let link = allegato.LinkAllegato.replace(
      '{tokenUtente}',
      sessione.Utente.TokenFacileWS,
    )
    let otp_stringified = `&otp=${$store_otp}`
    if (otp_stringified === '&otp=') {
      otp_stringified = '&'
    }

    link = `${link}${otp_stringified}`.replace(/token=(?=&|$)/i, '')
    window.open(link)
  }

  // $: UtenteApplicativoPuoFirmare = $Sviluppo || pratica.AccountLoginID === sessione.Utente.AccountLoginId
  $: utente_applicativo_puo_firmare =
    pratica.AccountLoginID === sessione.Utente.AccountLoginId

  $: pulsante_firma_sbloccato =
    clausole_accettate.filter(function pass(clausola) {
      return clausola
    }).length === numero_clausole_valide

  let contratto_firmato = false
  let contratto_inviato = false
</script>

{#await contratto_sviluppo( { promessa_contratto: contratto( { sessione, pratica, allegato }, ) }, )}
  <div
    class="p-4 grid w-full h-full justify-center content-center text-center"
    transition:scale
  >
    <Progresso />
  </div>
{:then contratto}
  {#if !contratto}
    <div class="p-4 w-full text-center">
      <span>Contratto non trovato</span>
    </div>
  {:else}
    {#if !contratto_inviato}
      <div class="p-4 w-full text-center">
        <div class="rounded-xl p-4 bg-gray-200 text-center">
          <p>Vuoi rivedere tutto il contratto? Premi qui</p>
          <div class="pt-4" />
          <button class="btn btn-primary" on:click={apri_contratto}>
            <span class="pr-2">Rivedi contratto</span>
            <Icona valore={mdiDownloadBox} />
          </button>
        </div>
      </div>

      <div class="pt-6" />

      <div class="p-4 w-full text-center text-lg">
        <span>Verifica e firma le clausole sottostanti</span>
      </div>

      <div class="pt-6" />

      <div class="p-2">
        <ClausoleContrattoFirma
          {sessione}
          {contratto}
          {abilita_pull_to_refresh}
          {disabilita_pull_to_refresh}
          {utente_applicativo_puo_firmare}
          bind:visualizza_app_bar_principale
          bind:clausole_accettate
          bind:numero_clausole_valide
        />
      </div>

      <div class="pt-6" />
    {/if}

    {#if contratto_firmato}
      {#await account_pratica()}
        <div class="pt-2" />
        <div
          class="p-4 grid w-full h-full justify-center content-center text-center"
          transition:scale
        >
          <Progresso />
        </div>
      {:then account}
        {#if account && account.AccountLoginID === pratica.AccountLoginID}
          <FormOperatoreContrattoFirma
            {sessione}
            {allegato}
            {pratica}
            {account}
          />
        {:else}
          <FormClienteContrattoFirma
            {sessione}
            {contratto}
            {allegato}
            {pratica}
            {dominio}
            bind:firme
            on:proposta-firma
            on:errore-proposta-firma
            on:contratto-inviato={function run() {
              contratto_inviato = true
            }}
          />
        {/if}
      {:catch error}
        <div class="p-2">
          {#if error.response && error.response.status === 404 && sessione.Utente.IsUtenteApplicativo}
            <div class="text-center attenzione">
              <div class="badge badge-error p-4 font-semibold">
                <span>Attenzione!</span>
              </div>
              <div class="pt-2" />
              <p class="text-center pl-4 pr-4">
                Questo contratto non è legato ad aclun account!
              </p>
            </div>
          {:else}
            <div class="text-center">
              {error}
            </div>
          {/if}
        </div>
      {/await}
    {:else}
      <div class="grid w-full justify-center">
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          class="btn"
          class:btn-primary={pulsante_firma_sbloccato}
          class:pointer-events-none={!pulsante_firma_sbloccato}
          on:mouseup={async function firma() {
            firma_in_corso = true
          }}
        >
          <Icona valore={mdiSignatureFreehand} />
          <span>Firma</span>
        </div>
      </div>
    {/if}
  {/if}
{:catch errore}
  {errore}
{/await}

<Popup
  icona={mdiEye}
  titolo={'Firma'}
  osserva_dimensioni
  bind:visibile={firma_in_corso}
  bind:larghezza_corpo={larghezza_corpo_firma}
  bind:altezza_corpo={altezza_corpo_firma}
  on:intenzione-apertura={function run() {
    visualizza_app_bar_principale = false
    disabilita_pull_to_refresh()
  }}
  on:intenzione-chiusura={function run() {
    visualizza_app_bar_principale = true
    abilita_pull_to_refresh()
  }}
  let:chiudi
>
  <div
    class="grid grid-x-center grid-y-center relative full-height"
    style="overflow:hidden"
  >
    <div
      class="absolute left right top bottom grid grid-x-center grid-y-center"
      style="pointer-events: none;"
    >
      <div class="indizio-firma" />
    </div>
    <CanvasManoLibera
      larghezza={larghezza_corpo_firma > altezza_corpo_firma
        ? larghezza_corpo_firma + 500
        : altezza_corpo_firma + 500}
      altezza={larghezza_corpo_firma > altezza_corpo_firma
        ? larghezza_corpo_firma + 500
        : altezza_corpo_firma + 500}
      let:pulisci
      let:get_immagine_base64
    >
      <div
        class="grid grid-cols-2 justify-center content-center fixed left-0 right-0 bottom-0 gap-2"
        style="z-index:2"
      >
        <button class="btn btn-ghost" on:click={pulisci}>
          <Icona valore={mdiTrashCan} />
          <span class="pl-2">Cancella</span>
        </button>
        {#if salvataggio_in_corso}
          <div
            class="p-4 grid w-full h-full justify-center content-center text-center"
            transition:scale
          >
            <Progresso />
          </div>
        {:else}
          <button
            class="btn btn-ghost"
            on:click={async function run() {
              try {
                const { immagine_base64, larghezza, altezza } =
                  await get_immagine_base64({
                    larghezza_massima: 125,
                    altezza_massima: 125,
                  })
                const firma_singola = {
                  ImmagineBase64: immagine_base64,
                  Larghezza: larghezza,
                  Altezza: altezza,
                }

                firme = [
                  firma_singola,
                  firma_singola,
                  firma_singola,
                  firma_singola,
                  firma_singola,
                ]

                contratto_firmato = true

                setTimeout(function run() {
                  chiudi()
                }, 100)
              } catch (e) {
                console.warn(e)
              }
            }}
          >
            <Icona valore={mdiSend} />
            <span class="pl-2">Salva</span>
          </button>
        {/if}
      </div>
    </CanvasManoLibera>
  </div>
</Popup>
