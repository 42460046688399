import axios from 'axios'
import { get } from 'svelte/store'
import { store_url_facilews3 } from ':stores/store_url_facilews3'
import { store_otp } from ':stores/store_otp'
import { store_sessione_app } from ':stores/store_sessione_app'

export async function trova_impostazioni_privacy() {
  const $store_url_facilews3 = get(store_url_facilews3)
  const $store_otp = get(store_otp)
  const $store_sessione_app = get(store_sessione_app)
  if (!$store_sessione_app) {
    throw new Error('Nessuna sessione trovata.')
  }
  try {
    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaFacileWS3<Array<import(':types').ImpostazionePrivacy>>>}
     */
    const Risposta = await axios.get(
      `${$store_url_facilews3}/Api/Rest/${$store_sessione_app.Utente.CodiceAgenzia}/ImpostazioniPrivacyAccount/${$store_sessione_app.Utente.AccountId}/FindAll?OTP=${$store_otp}&Token=${$store_sessione_app.Utente.TokenFacileWS}`,
    )
    return Risposta.data.data ?? []
  } catch (e) {
    console.error(e)
    return []
  }
}
