<!-- <style>
  .ricordami {
    overflow: auto;
  }
</style> -->

<script>
  import { store_messaggio } from ':stores/store_messaggio'
  import { firma_contratto } from ':scripts/firma_contratto'
  import { mdiKey, mdiOpenInApp, mdiSignatureFreehand } from '@mdi/js'
  import CampoDiTesto from ':components/campo-di-testo.svelte'
  import Progresso from ':components/progresso.svelte'
  import Icona from ':components/icona.svelte'
  import { crea_nuovo_mini_otp } from ':scripts/crea_nuovo_mini_otp'
  import { navigate } from 'svelte-routing'
  import { createEventDispatcher } from 'svelte'
  /**
   * @type {import(':types').Pratica}
   */
  export let pratica
  /**
   * @type {import(':types').Sessione}
   */
  export let sessione
  /**
   * @type {import(':types').AllegatoPratica}
   */
  export let allegato
  /**
   * @type {import(':types').ContrattoAllegatoPratica}
   */
  export let contratto
  /**
   * @type {import(':types').MetadatiDominio}
   */
  export let dominio
  /**
   * @type {[
   *  false|import(':types').FirmaContratto,
   *  false|import(':types').FirmaContratto,
   *  false|import(':types').FirmaContratto,
   *  false|import(':types').FirmaContratto,
   *  false|import(':types').FirmaContratto,
   * ]}
   */
  export let firme = [false, false, false, false, false] //massimo 5 firme, non inviare di più

  const emit = createEventDispatcher()

  let richieste_mini_otp = 0
  let email_cliente = sessione.Utente.Username
  let contratto_inviato = false
  let invio_in_corso = false
  let cognome = ''
  let nome = ''
  let codice_di_conferma = ''
  let secondi_attesa = 0

  $: if (contratto_inviato) {
    emit('contratto-inviato')
  }

  /**
   *
   * @param {{
   *  clausole: Array<{ Titolo: string; Testo: string }>,
   *  firme: [
   *    false | import(':types').FirmaContratto,
   *    false | import(':types').FirmaContratto,
   *    false | import(':types').FirmaContratto,
   *    false | import(':types').FirmaContratto,
   *    false | import(':types').FirmaContratto,
   *  ],
   * }} payload
   */
  function clausole_sono_state_firmate({ clausole, firme }) {
    let Ok = true
    clausole.forEach(function pass(clausola, i) {
      if ('' !== clausola.Testo.trim() && !firme[i]) {
        Ok = false
      }
    })
    return Ok
  }

  /**
   *
   * @param {{
   * contratto: {
   *  TitoloFirma1: string,
   *  TestoFirma1: string,
   *  TitoloFirma2: string,
   *  TestoFirma2: string,
   *  TitoloFirma3: string,
   *  TestoFirma3: string,
   *  TitoloFirma4: string,
   *  TestoFirma4: string,
   *  TitoloFirma5: string,
   *  TestoFirma5: string,
   * }
   * }} payload
   */
  function clausole({ contratto }) {
    const clausole = [
      { Titolo: contratto.TitoloFirma1, Testo: contratto.TestoFirma1 },
      { Titolo: contratto.TitoloFirma2, Testo: contratto.TestoFirma2 },
      { Titolo: contratto.TitoloFirma3, Testo: contratto.TestoFirma3 },
      { Titolo: contratto.TitoloFirma4, Testo: contratto.TestoFirma4 },
      { Titolo: contratto.TitoloFirma5, Testo: contratto.TestoFirma5 },
    ]
    return clausole
  }

  async function invia_conferma_contratto() {
    invio_in_corso = true
    if (
      !clausole_sono_state_firmate({
        clausole: clausole({ contratto }),
        firme,
      })
    ) {
      $store_messaggio = {
        testo: 'Tutte le clausole devono essere firmate prima di procedere.',
      }
      invio_in_corso = false
      return
    }
    try {
      await firma_contratto({
        sessione,
        allegato,
        firme,
        cognome,
        nome,
        mini_otp: codice_di_conferma,
      })
      $store_messaggio = { testo: 'Le firme sono state inviate.' }
      contratto_inviato = true
    } catch (e) {
      $store_messaggio = {
        //@ts-ignore
        testo: e.message,
      }
    }
    invio_in_corso = false
  }

  /** @type {NodeJS.Timeout|false} */
  let timer = false

  async function richiedi_mini_otp() {
    richieste_mini_otp++
    const [, errore] = await crea_nuovo_mini_otp({
      dominio,
      sessione,
    })
    if (timer) {
      clearTimeout(timer)
    }
    if (errore) {
      secondi_attesa = 0
      $store_messaggio = {
        testo: 'Errore durante la creazione del mini otp.',
      }
    } else {
      $store_messaggio = {
        testo: `Il codice di conferma è stato inviato a ${email_cliente}.`,
      }
      secondi_attesa = 120 // 2 minuti di attesa dopo aver richiesto l'OTP
      timer = setInterval(function run() {
        secondi_attesa--
        if (secondi_attesa <= 0 && timer) {
          clearTimeout(timer)
        }
      }, 1000)
    }
  }
</script>

<div class="pl-4 pr-4">
  <div class="p-4 w-full justify-center text-center">
    {#if !contratto_inviato}
      <button
        class="btn bg-red-600 text-red-200"
        disabled={secondi_attesa > 0}
        on:mouseup={richiedi_mini_otp}
      >
        <Icona valore={mdiSignatureFreehand} />
        {#if secondi_attesa > 0}
          {@const minuti = Math.floor(secondi_attesa / 60)}
          {@const secondi = secondi_attesa - minuti * 60}

          {#if richieste_mini_otp > 0}
            <span>Richiedi di nuovo OTP</span>
          {:else}
            <span>Richiedi OTP</span>
          {/if}

          {#if minuti <= 0}
            <span>({secondi} secondi)</span>
          {:else}
            <span>({minuti}:{secondi} minuti)</span>
          {/if}
        {:else}
          <span>Richiedi OTP</span>
        {/if}
      </button>
      <div class="pt-2" />

      {#if richieste_mini_otp > 0}
        <span>
          <!---->
          Il codice di conferma è stato inviato alla mail {email_cliente}
          <!---->
        </span>
      {:else}
        <span>
          <!---->
          Il codice di conferma verrà inviato alla mail {email_cliente}
          <!---->
        </span>
      {/if}

      <div class="pt-2" />
      <CampoDiTesto
        icona={mdiKey}
        bind:valore={codice_di_conferma}
        indizio="Codice di conferma"
      />
      <div class="pt-2" />

      <button
        disabled={0 === codice_di_conferma.trim().length || invio_in_corso}
        class="btn btn-primary w-full"
        on:click={invia_conferma_contratto}
      >
        {#if invio_in_corso}
          <Progresso />
        {:else}
          <span>Invia Conferma Contratto</span>
        {/if}
      </button>
    {:else}
      <div class="grid justify-content content-center">
        <h1>Operazione conclusa con successo</h1>
        <div class="pt-2" />
        <span>Il contratto è stato firmato correttamente</span>
        <div class="pt-4" />
        <button
          class="w-full btn btn-primary"
          on:click={function run() {
            navigate(`/pratica/${pratica.NumeroPratica}`)
          }}
        >
          <Icona valore={mdiOpenInApp} />
          <span class="pl-2">Vai a pratica</span>
        </button>
      </div>
    {/if}
  </div>
</div>
