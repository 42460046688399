<script>
  import LineaOrizzontale from ':components/linea-orizzontale.svelte'

  import { stringa_contiene } from ':scripts/string_includes'
  import { uuid } from ':scripts/uuid'
  import { mdiFilter } from '@mdi/js'

  import CampoDiTesto from ':components/campo-di-testo.svelte'
  import Spunta from ':components/spunta.svelte'

  /**
   * @type {import(':types').DomandaProfilazioneUtente}
   */
  export let domanda
  export let disabilitato = false

  let filtro = ''
  let id = uuid({})
  /**
   * @type {'SELEZIONATE' | 'NON-SELEZIONATE' | 'TUTTE'}
   */
  let mostra_risposte = 'TUTTE'

  function seleziona_tutte() {
    if (disabilitato) return
    domanda.OpzioniRisposta.forEach(function pass(opzione) {
      opzione.Selezionato = true
    })
    domanda = domanda
  }

  function DeselezionaTutte() {
    if (disabilitato) return
    domanda.OpzioniRisposta.forEach(function pass(opzione) {
      opzione.Selezionato = false
    })
    domanda = domanda
  }

  /**
   * 
   * @param {{
   * filtro: string,
   * opzioni: Array<import(':types').OpzioneRispostaProfilazioneUtente>,
    }} payload
   */
  function opzioni_filtrate({ filtro, opzioni }) {
    return opzioni.filter(function pass(opzione) {
      return (
        ('' === filtro ||
          stringa_contiene({ pagliaio: filtro, ago: opzione.Descrizione })) &&
        !opzione.Selezionato
      )
    })
  }
</script>

{#if 'MULTIPLA' === domanda.TipoRisposta}
  <div class="card w-full bg-base-100 shadow-xl">
    <div class="card-body">
      {#if domanda.OpzioniRisposta.length > 10}
        <div class="p-1">
          <CampoDiTesto
            {disabilitato}
            icona={mdiFilter}
            indizio="Filtra"
            bind:valore={filtro}
          />
          <div class="pt-2" />
          <select class="select w-full" bind:value={mostra_risposte}>
            {#each [{ name: 'Tutte', value: 'TUTTE' }, { name: 'Selezionate', value: 'SELEZIONATE' }, { name: 'Non selezionate', value: 'NON-SELEZIONATE' }] as Risposta}
              <option
                selected={Risposta.value === mostra_risposte}
                value={Risposta.value}
              >
                <span>{Risposta.name}</span>
              </option>
            {/each}
          </select>
        </div>
      {/if}
      <div class="pt-2" />
      {#each opzioni_filtrate( { filtro, opzioni: domanda.OpzioniRisposta }, ) as opzione}
        {#if 'TUTTE' === mostra_risposte}
          <Spunta
            disabilitata={disabilitato}
            attiva={opzione.Selezionato}
            testo={opzione.Descrizione}
            on:change={function run() {
              opzione.Selezionato = !opzione.Selezionato
            }}
          />
        {:else if 'SELEZIONATE' === mostra_risposte && opzione.Selezionato}
          <Spunta
            disabilitata={disabilitato}
            attiva={opzione.Selezionato}
            testo={opzione.Descrizione}
            on:change={function run() {
              opzione.Selezionato = !opzione.Selezionato
            }}
          />
        {:else if 'NON-SELEZIONATE' === mostra_risposte && !opzione.Selezionato}
          <Spunta
            disabilitata={disabilitato}
            attiva={opzione.Selezionato}
            testo={opzione.Descrizione}
            on:change={function run() {
              opzione.Selezionato = !opzione.Selezionato
            }}
          />
        {/if}
      {:else}
        <div class="text-center">Nessuna risposta disponibile</div>
      {/each}
      <div class="pt-2" />

      {#if domanda.OpzioniRisposta.length > 0}
        <LineaOrizzontale />
        <div class="p-1">
          {#each domanda.OpzioniRisposta.filter(function pass(opzione) {
            return opzione.Selezionato
          }) as opzione}
            <Spunta
              disabilitata={disabilitato}
              bind:attiva={opzione.Selezionato}
              testo={opzione.Descrizione}
            />
            <div class="pt-2" />
          {:else}
            <div class="text-center">Nessuna risposta selezionata</div>
          {/each}
        </div>
      {/if}
    </div>
  </div>
{:else if 'SINGOLA' === domanda.TipoRisposta}
  <div class="card w-full bg-base-100 shadow-xl">
    <div class="card-body">
      {#if domanda.OpzioniRisposta.length > 10}
        <div class="p-1">
          <CampoDiTesto
            icona={mdiFilter}
            indizio="Filtra"
            bind:valore={filtro}
          />
          <div class="pt-2" />
          <select class="select w-full" bind:value={mostra_risposte}>
            {#each [{ name: 'Tutte', value: 'TUTTE' }, { name: 'Selezionate', value: 'SELEZIONATE' }, { name: 'Non selezionate', value: 'NON-SELEZIONATE' }] as Risposta}
              <option
                selected={Risposta.value === mostra_risposte}
                value={Risposta.value}
              >
                <span>{Risposta.name}</span>
              </option>
            {/each}
          </select>
        </div>
      {/if}
      <div class="pt-2" />

      {#each opzioni_filtrate( { filtro, opzioni: domanda.OpzioniRisposta }, ) as Opzione, i}
        {#if 'TUTTE' === mostra_risposte}
          <Spunta
            disabilitata={disabilitato}
            attiva={Opzione.Selezionato}
            testo={Opzione.Descrizione}
            on:change={function run() {
              if (!Opzione.Selezionato) DeselezionaTutte()
              Opzione.Selezionato = !Opzione.Selezionato
            }}
          />
        {:else if 'SELEZIONATE' === mostra_risposte && Opzione.Selezionato}
          <Spunta
            disabilitata={disabilitato}
            attiva={Opzione.Selezionato}
            testo={Opzione.Descrizione}
            on:change={function run() {
              if (!Opzione.Selezionato) DeselezionaTutte()
              Opzione.Selezionato = !Opzione.Selezionato
            }}
          />
        {:else if 'NON-SELEZIONATE' === mostra_risposte && !Opzione.Selezionato}
          <Spunta
            disabilitata={disabilitato}
            attiva={Opzione.Selezionato}
            testo={Opzione.Descrizione}
            on:change={function run() {
              if (!Opzione.Selezionato) DeselezionaTutte()
              Opzione.Selezionato = !Opzione.Selezionato
            }}
          />
        {/if}
      {:else}
        <div class="text-center">Nessuna risposta disponibile</div>
      {/each}
      <div class="pt-2" />

      {#if domanda.OpzioniRisposta.length > 0}
        <LineaOrizzontale />
        <div class="p-1">
          {#each domanda.OpzioniRisposta.filter(function run(opzione) {
            return opzione.Selezionato
          }) as opzione}
            <Spunta
              disabilitata={disabilitato}
              bind:attiva={opzione.Selezionato}
              testo={opzione.Descrizione}
            />
            <div class="pt-2" />
          {:else}
            <div class="text-center">Nessuna risposta selezionata</div>
          {/each}
        </div>
      {/if}
    </div>
  </div>
{:else}
  Tipo risposta ({domanda.TipoRisposta}) sconosciuto.
{/if}
