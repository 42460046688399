<style lang="scss">
  .Menu {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-top: 1px solid #ccc;
    z-index: 999999999;
  }
  .Salvato {
    position: relative;
    min-height: 300px;
    width: 100%;
    height: 100%;
    display: grid;
    justify-content: center;
    align-content: center;
  }
</style>

<script>
  import FormDomandaProfilazioneUtente from ':components/form-domanda-profilazione-utente.svelte'
  import LineaOrizzontale from ':components/linea-orizzontale.svelte'
  import axios from 'axios'
  import { IOTNULL } from ':constants'
  import {
    mdiCheckCircle,
    mdiClose,
    mdiPageNext,
    mdiPagePrevious,
  } from '@mdi/js'
  import { store_messaggio } from ':stores/store_messaggio'
  import { store_url_sferanet } from ':stores/store_url_sferanet'

  import { fly, scale } from 'svelte/transition'
  import { Swiper, SwiperSlide } from 'swiper/svelte'
  import Icona from ':components/icona.svelte'
  import Progresso from ':components/progresso.svelte'

  /**
   * @type {{
   *  QuestionarioTestataID: string
   *  ProfilazioneTestataID: string
   *  ParentType: 'ACC_Account' | 'CRM_Lead' | 'PRT_PraticaTestata'
   *  ParentID: string
   * }}
   */
  export let opzioni
  /**
   * @type {import(':types').Sessione}
   */
  export let sessione

  let indice = 0
  let massimo = 0
  /**
   * @type {Swiper}
   */
  let quadro_istanza_swiper
  /**
   * @type {Swiper}
   */
  let istanza_swiper
  let salvataggio_in_corso = false
  let salvato = false

  /**
   * @returns {Promise<false | import(':types').ProfilazioneUtente>}
   */
  async function TrovaProfilazione() {
    /**
     * @type {import(':types').AxiosResponse<import(':types').RispostaSferaNet<[import(':types').ProfilazioneUtente?]>>}
     */
    const Risposta = await axios.post(
      `${$store_url_sferanet}/CompFacileLogin/CRMProfilazioneQuestionario.json`,
      JSON.stringify(opzioni),
      {
        headers: {
          'codiceagenzia': sessione.Utente.CodiceAgenzia,
          'accesstoken': sessione.Utente.AccessToken,
          'x-http-method-override': 'Find',
          'content-type': 'application/json',
        },
      },
    )

    // @ts-ignore
    if (IOTNULL === Risposta.data) {
      store_messaggio.set({ testo: IOTNULL })
      return false
    }

    if (Risposta.data.code >= 300 || Risposta.data.data.length === 0) {
      store_messaggio.set({ testo: 'Questionario non trovato.' })
      return false
    }
    const profilazione = Risposta.data.data[0]

    massimo = profilazione?.Domande.length ?? 0

    return profilazione ?? false
  }

  /**
   * Salva le modifiche sul server.
   * @param {{profilazione_utente:import(':types').ProfilazioneUtente}} payload
   */
  async function save({ profilazione_utente }) {
    salvataggio_in_corso = true
    /**
     * @type {import(':types').RispostaSferaNet<string>}
     */
    await axios.post(
      `${$store_url_sferanet}/CompFacileLogin/CRMProfilazioneQuestionario.json`,
      JSON.stringify({
        Json: JSON.stringify(profilazione_utente),
      }),
      {
        headers: {
          'codiceagenzia': sessione.Utente.CodiceAgenzia,
          'accesstoken': sessione.Utente.AccessToken,
          'x-http-method-override': 'Save',
          'content-type': 'application/json',
        },
      },
    )
    salvataggio_in_corso = false
    salvato = true
  }

  function slide_precedente() {
    if (indice > 0) istanza_swiper.slideTo(--indice)
  }

  function slide_successiva() {
    if (indice <= massimo) istanza_swiper.slideTo(++indice)
  }

  $: AggiornamentoIstanzaSwiper({ istanza_swiper: quadro_istanza_swiper })
  /**
   * @param {{istanza_swiper: Swiper}} payload
   */
  function AggiornamentoIstanzaSwiper({ istanza_swiper }) {
    if (!istanza_swiper) return
    istanza_swiper = quadro_istanza_swiper.swiper()
    istanza_swiper.allowTouchMove = false
  }
</script>

{#await TrovaProfilazione()}
  <div
    class="p-4 grid w-full h-full justify-center content-center text-center"
    transition:scale
  >
    <Progresso />
  </div>
{:then profilazione_utente}
  {#if !profilazione_utente}
    <div class="pt-4" />
    <h4 class="grid" in:fly={{ y: 100 }}>
      <div class="justify-self-center">
        Questionario {opzioni.ParentType === 'PRT_PraticaTestata'
          ? 'pratica'
          : 'account'}
        non trovato
      </div>
    </h4>
  {:else if salvato}
    <div class="Salvato" in:fly={{ y: 100 }}>
      <h4>Abbiamo registrato le tue risposte, grazie per la collaborazione!</h4>
    </div>
  {:else}
    <div
      class="w-full rounded-xl p-4 bg-base-200 font-semibold text-xl capitalize text-center"
    >
      {profilazione_utente.Descrizione}
    </div>
    <div class="pt-4" />
    <Swiper slidesPerView={1} bind:this={quadro_istanza_swiper}>
      {#each profilazione_utente.Domande as domanda, i}
        <SwiperSlide>
          <div class="p-2">
            <h6>Domanda n. {i + 1}</h6>
            <div class="pt-4" />
            {domanda.Domanda}
            <div class="pt-4" />
            <LineaOrizzontale />
            <div class="pt-2" />
            <FormDomandaProfilazioneUtente
              disabilitato={salvataggio_in_corso || salvato}
              {domanda}
            />
          </div>
        </SwiperSlide>
      {/each}
      <SwiperSlide>
        <div class="grid grid-x-center grid-y-center">
          <button
            class="btn btn-primary"
            on:click={function run() {
              save({ profilazione_utente })
            }}
          >
            <span class="pr-2">Invia</span>
            <Icona valore={mdiPageNext} />
          </button>
        </div>
      </SwiperSlide>
    </Swiper>

    <div class="p-4" />
    <div class="Menu grid grid-cols-2 p-1">
      {#if indice > 0}
        <button
          class="btn btn-primary"
          disabled={salvataggio_in_corso || salvato}
          on:click={slide_precedente}
        >
          <Icona valore={mdiPagePrevious} />
          <span class="pl-2">Indietro</span>
        </button>
      {:else}
        <span />
      {/if}

      {#if indice < profilazione_utente.Domande.length - 1}
        <button class="btn btn-primary" on:click={slide_successiva}>
          <span class="pr-2">Avanti</span>
          <Icona valore={mdiPageNext} />
        </button>
      {:else if salvataggio_in_corso}
        <button class="btn btn-primary" on:click={slide_successiva}>
          <Progresso />
        </button>
      {:else}
        <button
          class="btn btn-primary"
          disabled={salvato}
          on:click={function run() {
            save({ profilazione_utente })
          }}
        >
          {#if salvato}
            <span class="pr-2">Ok</span>
            <Icona valore={mdiCheckCircle} />
          {:else}
            <span class="pr-2">Fine</span>
            <Icona valore={mdiClose} />
          {/if}
        </button>
      {/if}
    </div>
  {/if}
{:catch errore}
  <div class="grid grid-x-center grid-y-center full-height full-width">
    {errore}
  </div>
{/await}
